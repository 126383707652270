import React from 'react';
import { ModalCustom } from 'components/ModalCustom';

import './style.scss';
import { Plan, Subscription, UserSubscriptionConfig } from 'types';
import { CustomButton } from 'components/Button';
import { get } from 'lodash';

type props = {
  isOpen: boolean;
  toggle: () => void;
  plans: Plan[];
  setSelectedPlan: (id: string) => void;
  selectedPlan: string;
  isTrialAvailable: boolean;
  userSubscriptionConfig: UserSubscriptionConfig | null;
  handleSubmit: () => void;
  activeSubscription: Subscription | null;
  isUpdateModal?: boolean;
  isNewUser?: boolean;
  handleNoCard?: () => void;
  onCloseTracking?: () => void;
  handleSelectPlanTracking?: () => void;
};

export const ModalUpdatePlan = ({
  isOpen = false,
  toggle,
  plans,
  selectedPlan,
  setSelectedPlan,
  isTrialAvailable = false,
  userSubscriptionConfig,
  handleSubmit,
  activeSubscription,
  isUpdateModal = false,
  isNewUser = false,
  handleNoCard,
  onCloseTracking,
  handleSelectPlanTracking
}: props) => {
  const onCloseModal = () => {
    toggle();
  };

  const currentPlan = plans?.find((p) => p.id === selectedPlan);
  const isSubWithNoCard = get(userSubscriptionConfig, 'isEndOfTrialPeriod', false) && isNewUser;
  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        className="modal-lg modal-update-plan"
        toggle={onCloseModal}
        onClose={onCloseModal}
        onCloseTracking={onCloseTracking}
        isSupportClose>
        <div className="update-plan-container">
          <div className="mb-5 pb-4 mt-3">
            <div className="update-plan-title">
              {!isUpdateModal && isTrialAvailable ? (
                <>
                  {`Special Offer: We're giving ${userSubscriptionConfig?.trialPeriod + '-' + userSubscriptionConfig?.trialPeriodTimeUnit} Trial for only`}{' '}
                  <span>${userSubscriptionConfig?.trialFee || 0}</span>!
                </>
              ) : (
                'Select a Subscription Plan'
              )}
            </div>
            {!isUpdateModal && isTrialAvailable && (
              // <div className="update-plan-subtitle">{`After ${userSubscriptionConfig?.trialPeriod + '-' + userSubscriptionConfig?.trialPeriodTimeUnit}, you will be subscribed to the selected plan. Cancel any time.`}</div>
              <div className="update-plan-subtitle">{`After ${userSubscriptionConfig?.trialPeriod + '-' + userSubscriptionConfig?.trialPeriodTimeUnit}, you can pay for your selected plan or cancel anytime`}</div>
            )}
          </div>
          {!isSubWithNoCard ? (
            <>
              {/* <div className="update-plan-plans">
                {plans.map((plan) => (
                  <div
                    className={plan?.id === selectedPlan ? 'plan-item-container selected' : 'plan-item-container'}
                    onClick={() => setSelectedPlan(plan?.id)}
                    key={plan.id}>
                    <div className="plan-name">
                      {plan.productName?.replace(' Plan', '')}
                      {plan.prices[0].pricePercentageSaving && (
                        <div className="discount-percentage">{plan.prices[0].pricePercentageSaving}% off</div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="plan-details">
                {currentPlan?.prices[0].originPrice && (
                  <span
                    className={`plan-original-price ${currentPlan?.prices[0].priceRecurringInterval === 'month' ? 'isMonth' : ''}`}>{`$${currentPlan?.prices[0].originPrice} `}</span>
                )}
                <span className="discount-price">${currentPlan?.prices[0].priceUnitAmountDecimal}</span>
                <span className="plan-unit"> per {currentPlan?.prices[0].priceRecurringInterval}</span>
              </div> */}

              <div className="update-plan-plans">
                {plans.map((plan) => (
                  <div
                    className={plan?.id === selectedPlan ? 'plan-item-container selected' : 'plan-item-container'}
                    onClick={() => setSelectedPlan(plan?.id)}
                    key={plan.id}>
                    <div className="plan-radio">
                      <span></span>
                    </div>
                    <div className="plan-name">
                      {/* {plan.productName?.replace(' Plan', '')} */}
                      <div>
                        <h3>
                          {plan?.productName}{' '}
                          {plan?.prices[0].pricePercentageSaving && (
                            <span className="discount-percentage">{plan.prices[0].pricePercentageSaving}% off</span>
                          )}
                        </h3>
                      </div>

                      <div
                        className={`plan-details ${plan?.prices[0].priceRecurringInterval === 'month' ? 'isMonth' : ''}`}>
                        {plan?.prices[0].originPrice && (
                          <span
                            className={`plan-original-price ${plan?.prices[0].priceRecurringInterval === 'month' ? 'isMonth' : ''}`}>{`$${plan?.prices[0].originPrice}`}</span>
                        )}
                        <span className="discount-price"> ${plan?.prices[0].priceUnitAmountDecimal}</span>
                        <span className="plan-unit"> per {plan?.prices[0].priceRecurringInterval}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="plan-details">
                {currentPlan?.prices[0].originPrice && (
                  <span
                    className={`plan-original-price ${currentPlan?.prices[0].priceRecurringInterval === 'month' ? 'isMonth' : ''}`}>{`$${currentPlan?.prices[0].originPrice} `}</span>
                )}
                <span className="discount-price">${currentPlan?.prices[0].priceUnitAmountDecimal}</span>
                <span className="plan-unit"> per {currentPlan?.prices[0].priceRecurringInterval}</span>
              </div> */}
            </>
          ) : null}

          <div className="update-plan-btn-container">
            <div className="update-plan-text-promote">
              Get <span>$700+</span> Automatic, maximum savings on your cards. Feel confident on your purchases.
            </div>
            {isSubWithNoCard ? (
              <CustomButton className="update-plan-submit-button" onClick={handleNoCard ? handleNoCard : undefined}>
                Subscribe now
              </CustomButton>
            ) : (
              <CustomButton
                className="update-plan-submit-button"
                onClick={() => {
                  if (!isUpdateModal) {
                    handleSelectPlanTracking?.();
                  }
                  handleSubmit();
                }}
                disabled={
                  isUpdateModal ? activeSubscription?.userSubscriptionPrice?.priceProductId === selectedPlan : false
                }>
                {isUpdateModal ? 'Update' : 'Select Plan'}
              </CustomButton>
            )}
          </div>
        </div>
      </ModalCustom>
    </>
  );
};
