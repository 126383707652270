import React from 'react';
import { isMobile } from 'react-device-detect';
// import { Redirect } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Navigate } from 'react-router-dom';
import step1 from 'assets/images/extension-step-01.png';
import step2 from 'assets/images/extension-step-02.png';
import step3 from 'assets/images/extension-step-03.png';
import step4 from 'assets/images/extension-step-04.png';

import './styles.scss';

export const EnableExtensionCarouselItem = ({ img_src, step }: { img_src: string; step: number }) => {
  return (
    <div className="EnableExtensionCarouselItem">
      <div
        className={
          step === 4 ? 'EnableExtensionCarouselItem-img-wrapper item-4' : 'EnableExtensionCarouselItem-img-wrapper'
        }>
        <img src={img_src || ''} alt="step-desc" style={step === 4 && isMobile ? { width: '70%' } : {}} />
      </div>
      <div className="EnableExtensionCarouselItem-steps">
        <ol>
          <li className={step === 1 ? 'item-selected' : ''}>Tap in the address bar</li>
          <li className={step === 2 ? 'item-selected' : ''}>Tap manage extensions</li>
          <li className={step === 3 ? 'item-selected' : ''}>Turn on Uthrive</li>
          <li className={step === 4 ? 'item-selected' : ''}>Set Allow on Every Website</li>
        </ol>
      </div>
    </div>
  );
};

export default function EnableExtension(props: any) {
  // if (!isMobile) {
  //   return <Navigate to={'/stop-using-web'} />;
  // }
  return (
    <div className="EnableExtension-wrapper">
      <div className="EnableExtension-header navbar">{/* <LogoFont /> */}</div>
      <div className="EnableExtension-body">
        <h1>First we need you to enable the extension for Safari</h1>
        <div className="EnableExtension-slider-wrapper">
          <Carousel
            autoPlay={true}
            centerMode={true}
            centerSlidePercentage={100}
            infiniteLoop={true}
            showArrows={false}
            showThumbs={false}
            showStatus={false}>
            <EnableExtensionCarouselItem img_src={step1} step={1} />
            <EnableExtensionCarouselItem img_src={step2} step={2} />
            <EnableExtensionCarouselItem img_src={step3} step={3} />
            <EnableExtensionCarouselItem img_src={step4} step={4} />
          </Carousel>
        </div>
      </div>
    </div>
  );
}
