// import { Footer } from 'components';
// import { LogoFont } from 'components/common';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import './styles.scss';
import Ulogo from 'assets/images/Uthrive-logo-short128.png';
import wallet from 'assets/images/wallet.gif';
import step3 from 'assets/images/extension-step-04.png';
import { useAuthStore } from 'store';
import { sendLinkDownloadApp } from 'services';
// import { getUserRegister } from 'utils/localStorages';
// import { sendLinkDownloadApp } from 'api';

export default function StopUsingWeb(props: any) {
  const { user } = useAuthStore();

  const [phone, setPhone] = useState('');
  const [err, setErr] = useState(false);
  useEffect(() => {
    const data = user;
    if (data?.phone_number) {
      setPhone(data?.phone_number);
    }
  }, []);

  const handleChangePhone = (e: any) => {
    setErr(false);
    setPhone(e.target.value);
  };

  const handleClickSendLink = () => {
    if (phone) {
      sendLinkDownloadApp({ phoneNumber: phone }).catch((err) => {
        console.log(err);
        setErr(true);
      });
    } else {
      setErr(true);
    }
  };

  const handleClickEnableExtension = () => {
    window.open('https://uthrive.app.link/enable-extension', '_blank');
  };

  const handleClickHowItWork = () => {
    window.open('https://uthrive.app.link/how-see-it-work-extension', '_blank');
  };

  return (
    <div className="HomeAccessMobile-wrapper">
      <div className="HomeAccessMobile-body">
        <div className="HomeAccessMobile-qrcode-container">
          <div className="notify-text">
            <span>Hi {user ? user?.username : 'there'},</span>
            <br />
            Now Uthrive can only be accessed via our
            <br />
            mobile app – it&rsquo;s simple, easy to use and safe.
          </div>
          <div className="qr-wrapper">
            <div className="qr-text">Scan to download the app</div>
            <QRCode size={60} value={process.env.REACT_APP_LINK_GET_APP || 'https://uthrive.app.link/'} />
          </div>
        </div>
        <div className="HomeAccessMobile-contents">
          <div className="HomeAccessMobile-card">
            <div className="img-wrapper">
              <img src={Ulogo} alt="uthrive" />
            </div>
            <div className="card-text">Send a link to your phone number to download Uthrive app.</div>
            <div className="input-wrapper">
              <input value={phone} onChange={handleChangePhone} name="phone" />
              <div className="line"></div>
              <label>Your phone number (recommended)</label>
              {err && <div className="err">Phone invalid</div>}
            </div>
            <button type="button" className="btn btn-base btn-signup btn-card" onClick={handleClickSendLink}>
              Send a link
            </button>
          </div>
          <div className="HomeAccessMobile-card">
            <div className="img-wrapper-no-box-shadow">
              <img src={wallet} alt="uthrive" />
            </div>
            <div className="card-text">
              Enable Uthrive extension to know the best card to use before shopping online.
            </div>
            <button type="button" className="btn btn-base btn-signup btn-card" onClick={handleClickEnableExtension}>
              Enable extension
            </button>
            <div className="how-it-work" onClick={handleClickHowItWork}>
              <div className="play-btn">
                <div className="triangle"></div>
              </div>
              <div className="how-it-work-text">See how it works</div>
            </div>
          </div>
          <div className="HomeAccessMobile-card">
            <img src={step3} />
            <div className="card-text">Set Allow on Every website</div>
          </div>
        </div>
      </div>
    </div>
  );
}
