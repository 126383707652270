import React from 'react';
import { ModalCustom } from 'components/ModalCustom';
import logo from 'assets/images/logo.svg';
import google from 'assets/images/google-icon.png';
import facebook from 'assets/images/facebook.png';
import email from 'assets/images/email-icon.png';
import './style.scss';
import { trackingEvents } from 'const';

type props = {
  isOpen: boolean;
  toggle: () => void;
  handleClickEmail?: () => void;
  isSignin?: boolean;
  handleClickSignup?: () => void;
  handleClickLogin?: () => void;
  handleClickFacebook: () => void;
  handleClickGoogle: () => void;
  handleCloseTracking?: () => void;
  handlePrivacyTracking?: () => void;
  handleTCTracking?: () => void;
};

export const ModalAuthenMethods = ({
  isOpen = false,
  toggle,
  handleClickEmail,
  isSignin = false,
  handleClickSignup,
  handleClickLogin,
  handleClickFacebook,
  handleClickGoogle,
  handleCloseTracking,
  handlePrivacyTracking,
  handleTCTracking
}: props) => {
  const onCloseModal = () => {
    toggle();
  };

  return (
    <>
      <ModalCustom
        isOpen={isOpen}
        className="modal-blur modal-lg modal-authen-methods"
        onClose={onCloseModal}
        onCloseTracking={handleCloseTracking}
        isSupportClose>
        <div>
          <img src={logo} alt="logo" />
        </div>
        <div className="modal-container">
          <div className="title-container">
            <h1 className="title">{isSignin ? 'Welcome! Sign in to your account.' : 'Create an Account.'}</h1>
            <div className="subTitle">
              {isSignin ? (
                <div>
                  Don&apos;t have an account?{' '}
                  <span onClick={handleClickSignup} className="text-link">
                    Sign up here.
                  </span>
                </div>
              ) : (
                <div>
                  Unlock <span className="highlight">$700+</span> with maximum rewards and savings on your purchases
                  with credit or debit cards.
                </div>
              )}
            </div>
          </div>
          <div className="modal-btn-authen-methods-container">
            <div className="btn-social" onClick={handleClickGoogle}>
              <img src={google} alt="google" />
              <div className="btn-title">{`${isSignin ? 'Sign in' : 'Sign up'} with Google`}</div>
            </div>
            <div className="btn-social" onClick={handleClickFacebook}>
              <img src={facebook} alt="facebook" />
              <div className="btn-title">{`${isSignin ? 'Sign in' : 'Sign up'} with Facebook`}</div>
            </div>
            <div className="btn-social" onClick={handleClickEmail}>
              <img src={email} alt="email" />
              <div className="btn-title">{`${isSignin ? 'Sign in' : 'Sign up'} with Email`}</div>
            </div>
          </div>
          {!isSignin && (
            <div className="terms-conditions">
              {`By submitting this registration form, I agree with Uthrive’s `}
              <span
                className="btn-text"
                onClick={() => {
                  handleTCTracking?.();
                  window.open(`https://www.uthrive.club/terms-and-conditions/`, '_blank');
                }}>
                Terms & Conditions
              </span>
              {` and `}
              <span
                className="btn-text"
                onClick={() => {
                  handlePrivacyTracking?.();
                  window.open(`https://www.uthrive.club/privacy-policy/`, '_blank');
                }}>
                Privacy Policy
              </span>
              {`. I understand that I am providing written instructions in accordance with the Fair Credit Reporting Act and other applicable law for Uthrive to request and receive information about me from third parties, including but not limited to a copy of my consumer report and score from consumer reporting agencies, at any time for so long as I have an active Uthrive account. I further authorize Uthrive to retain a copy of my credit information for it’s use and to match with credit products.`}
            </div>
          )}
        </div>
      </ModalCustom>
    </>
  );
};
