import logo from 'assets/images/white-logo.svg';
import google from 'assets/images/google.png';
import apple from 'assets/images/apple.png';
import './style.scss';
import { BannerFooter } from 'components/BannerFooter';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppStore, useAuthStore, usePlaidStore } from 'store';
import { ACTIVE_STATUS, trackingEvents } from 'const';
import { LinkPlaidProgressBar } from 'components/LinkPlaidProgressBar';
import { clickTracking } from 'services';

export const AppFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { progress, showProgress, showPlaidLoader } = usePlaidStore();
  const { recommendedCardHeadline, subscriptions } = useAppStore();
  const { token, user, shadowId } = useAuthStore();
  const activeSub = subscriptions?.find((sub) => ACTIVE_STATUS.indexOf(sub.userSubscriptionStatus) !== -1) || false;

  const isLoggedIn = token;

  const isLanding = location?.pathname === '/landing';
  const isPremiumPage = location?.pathname === '/premium';
  const isHome = location?.pathname === '/';
  const isCardPage = location?.pathname?.includes('/cards');
  const isComparePage = location?.pathname?.includes('/cards/compare-rewards');
  const isOfferDetails = location?.pathname.includes('/offers/details');
  const isRatingPage = location?.pathname.includes('/rating');
  const isLinkedAccount = location?.pathname.includes('/linked-account');
  const isExtensionPage = location?.pathname.includes('/uninstall-extension');
  const isShow = !activeSub && !isLanding && !isPremiumPage && !isCardPage && !isRatingPage;
  const isPage = !isOfferDetails && !isComparePage && !isLinkedAccount && !isExtensionPage;
  const premiumMesg = isShow ? 'Unlock $700+ credits with Uthrive Premium' : '';

  const handleClickSee = () => {
    console.log('see');
    handleNotiClickTracking();
    if (premiumMesg && (!isHome || !recommendedCardHeadline)) {
      navigate('/premium');
    }
  };

  const handleCloseTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_PREMIUM_NOTI_CLOSE;
    await trackingData(key);
  };

  const handleNotiClickTracking = async () => {
    const key = trackingEvents.DESKTOP_SIGNIN_HOME_PREMIUM_NOTI_CLICK;
    await trackingData(key);
  };

  const trackingData = async (key: string, eventData = {}) => {
    if (shadowId || user?.userId) {
      const resp = await clickTracking({
        eventType: key,
        shadowUserId: user?.userId ? user?.userId : shadowId || '',
        deviceType: 'web',
        eventData: eventData
      });
      if (resp) {
        console.log(`success tracking ${key}`);
        console.log('completed');
      }
    }
  };

  return (
    <div>
      {showProgress && !showPlaidLoader && <LinkPlaidProgressBar value={progress} />}
      {isPage && (
        <>
          <BannerFooter
            content={isHome && recommendedCardHeadline ? recommendedCardHeadline : premiumMesg}
            handleClick={handleClickSee}
            isShowArrow={!isHome || recommendedCardHeadline === ''}
            handleCloseTracking={handleCloseTracking}
          />
          <div className="footer">
            <div className="footer-container">
              <div className="footer-logo-container">
                <img src={logo} className="footer-logo" alt="logo" />
              </div>
              <div className="footer-body">
                {!isLoggedIn && (
                  <div className="footer-menu">
                    <div className="footer-body--item">
                      <div className="footer-title">About</div>
                      <a
                        className="footer-custom-navlink"
                        href="https://www.uthrive.club/about-us"
                        target="_blank"
                        rel="noreferrer">
                        Our Story
                      </a>
                      <a className="footer-custom-navlink" href="mailto:press@Uthrive.club">
                        press@Uthrive.club
                      </a>
                    </div>
                    <div className="footer-body--item">
                      <div className="footer-title">Support</div>
                      <a className="footer-custom-navlink" href="mailto:support@Uthrive.club">
                        support@Uthrive.club
                      </a>
                      <a
                        className="footer-custom-navlink"
                        href="https://www.uthrive.club/help"
                        target="_blank"
                        rel="noreferrer">
                        Help
                      </a>
                    </div>
                    <div className="footer-body--item">
                      <div className="footer-title">Legal</div>
                      <a
                        className="footer-custom-navlink"
                        href="https://www.uthrive.club/terms-and-conditions"
                        target="_blank"
                        rel="noreferrer">
                        Terms & Conditions
                      </a>
                      <a
                        className="footer-custom-navlink"
                        href="https://www.uthrive.club/privacy-policy"
                        target="_blank"
                        rel="noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                    <div className="footer-body--item">
                      <div className="footer-title">App</div>
                      <a
                        className="footer-custom-navlink"
                        href="https://uthrive.app.link/download-uthrive-android"
                        target="_blank"
                        rel="noreferrer">
                        <img src={google} alt="play store" className="footer-play-store" />
                      </a>
                      <a
                        className="footer-custom-navlink"
                        href="https://uthrive.app.link/download-uthrive-ios"
                        target="_blank"
                        rel="noreferrer">
                        <img src={apple} alt="apple store" className="footer-apple-store" />
                      </a>
                    </div>
                  </div>
                )}
                <div className="footer-text">
                  All corporate logos and trademarks are a illustrative purposes only and are not a recommendation, an
                  offer to sell, or a solicitation of an offer. Trademarks and logos are the property of their
                  respective owners and do not represent endorsements of any kind. Examples and/or pictures above are
                  for illustrative purposes only and are not real customers. View our{' '}
                  <a href="https://www.uthrive.club/disclaimer" target="_blank" rel="noreferrer">
                    Disclaimer
                  </a>{' '}
                  for more details.
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div>© Copyright 2022 Uthrive Inc. All rights reserved.</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
